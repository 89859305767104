import { render, staticRenderFns } from "./EcosystemAnimation.vue?vue&type=template&id=3ddac311&scoped=true"
import script from "./EcosystemAnimation.vue?vue&type=script&lang=js"
export * from "./EcosystemAnimation.vue?vue&type=script&lang=js"
import style0 from "./EcosystemAnimation.vue?vue&type=style&index=0&id=3ddac311&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ddac311",
  null
  
)

export default component.exports