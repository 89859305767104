<template>
    <section :id="cmsBlock.anchorTag" class="ecosystem" :class="cmsBlock.style">

        <div ref="wrapper" class="animation">

            <Lottie :options="lottieOptions" v-on:animCreated="handleAnimation"/>

            <div class="logos">
                <a
                    v-for="(el, i) in logosSorted"
                    :href="url(el.logo)"
                    :target="target(el.logo)"
                    :style="placeLogo(i, el.logo)"
                    :class="el.class"
                >
                    <img :src="getImageUrl(el.logo.image.data.attributes.url)">
                </a>
            </div>

        </div>

        <div v-html="md(localize(cmsBlock.subline))" class="cms-content"></div>

    </section>
</template>

<script>
    import ecosystemAnimationJson from '@/assets/ecosystem.json'
    import { VectorCartesian, VectorPolar } from '@/helper'

    export default {
        name: 'EcosystemAnimation',

        data() {
            return {
                anim: null,

                totalLogos: this.cmsBlock.logosStep1.length + this.cmsBlock.logosStep2.length + this.cmsBlock.logosStep3.length,

                baseTimeOffset: 3500,

                logosSorted: [],

                animationVisible: false,
                finishedSorting: false
            }
        },

        props: [
            'cmsBlock'
        ],

        components: {
            Lottie: () => import('vue-lottie').then(m => m.default),
        },

        computed: {
            lottieOptions() {
                return {
                    animationData: ecosystemAnimationJson,
                    renderer: 'canvas',
                    autoplay: false,
                    loop: false
                }
            }
        },

        methods: {
            handleAnimation(anim) {
                this.anim = anim
            },

            play() {
                this.anim.play()
                if(this.finishedSorting){
                    this.showLogos()
                }
            },
            
            showLogos() {
                var self = this
                this.logosSorted.forEach(function(el){
                    setTimeout(function(){
                        el.class = {
                            ...el.class,
                            visible: true
                        }
                    }, (el.step + 1) * self.baseTimeOffset)
                })
            },

            placeLogo(i, logo) {
                var rect = this.$refs.wrapper.getBoundingClientRect()
                var r = rect.height / 2.5
                var phi = 2 * Math.PI / this.totalLogos * i

                var polarPosition = new VectorPolar(r, phi)
                var position = polarPosition.cartesian()

                return {
                    transform: `translate(${position.x}px, ${position.y}px)`
                }
            },

            url(logo) {
                switch(logo.linkType){
                    case 'internal':
                        return logo.internalUrl
                    case 'external':
                        return logo.externalUrl
                    case 'anchor':
                        return logo.anchor
                }
            },

            target(logo) {
                switch(logo.linkType){
                    case 'external':
                        return '_blank'
                    default:
                        return '_self'
                }
            }
        },

        mounted() {
            var self = this

            // get the number of logos from the step with the most logos
            const longestLogoSet = Math.max(...[
                this.cmsBlock.logosStep1.length,
                this.cmsBlock.logosStep2.length,
                this.cmsBlock.logosStep3.length
            ])

            if(window.innerWidth >= 825){
                // Logo sets with [A1, A2, A3], [B1, B2, B3], [C1, C2, C3]
                // will be mixed into a single array as [A1, B1, C1, A2, B2, C2, A3, B3, C3]
                // --------------
                // Iterate through the range of max number of logos per set
                Array(longestLogoSet).fill(0).map(function(_, i){
                    // iterate through the 3 logo sets
                    Array(3).fill(0).map(function(__, n){
                        // if the current set is inside the range i
                        if(self.cmsBlock[`logosStep${n+1}`].length > i){
                            // add the logo to the sorted array
                            var logoConfig = {
                                logo: self.cmsBlock[`logosStep${n+1}`][i],
                                step: n,
                                class: {}
                            }
                            logoConfig.class[`step${n+1}`] = true
                            self.logosSorted.push(logoConfig)
                        }
                    })
                })
            } else {
                Array(3).fill(0).map(function(__, n){
                    self.cmsBlock[`logosStep${n+1}`].forEach(function(logo){
                        self.logosSorted.push({
                            logo: logo,
                            step: 1,
                            class: {}
                        })
                    })
                })
            }
            this.finishedSorting = true

            var observer = new IntersectionObserver(
                function(entries) {
                    if(entries[0].isIntersecting === true){
                        self.animationVisible = true
                        self.play()
                    }
                },{
                    threshold: [0.5] 
                }
            )
            observer.observe(this.$refs.wrapper)

            window.addEventListener('resize', function(){
                self.$forceUpdate()
            })
        },

        watch: {
            finishedSorting: {
                handler() {
                    if(this.finishedSorting && this.animationVisible){
                        this.showLogos()
                    }
                }
            },

            animationVisible: {
                handler() {
                    if(this.finishedSorting && this.animationVisible){
                        this.showLogos()
                    }
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .animation {
        height: calc(66vh - 300px) !important;
        width: auto !important;
        padding: 150px;
        position: relative;

        @include breakpoint('mobile') {
            padding: 30px;
            height: auto !important;
        }
    }

    .logos {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        @include breakpoint('mobile') {
            position: relative;
            flex-wrap: wrap;
            justify-content: space-around;
        }

        a {
            position: absolute;
            opacity: 0;
            transition: 2s;

            &.step1 {
                img {
                    width: 17vh;

                    @include breakpoint('mobile') {
                        width: unset;
                    }
                }
            }

            &.visible {
                opacity: 1;
            }

            @include breakpoint('mobile') {
                transform: none !important;
                position: relative;
                opacity: 1;
            }

            img {
                width: 7vh;
                height: auto;
                filter: grayscale(1);
                transition: .16s;

                @include breakpoint('mobile') {
                    width: 175px;
                    padding: 15px;
                }

                &:hover {
                    filter: grayscale(0);
                }
            }
        }
    }

    .cms-content {
        max-width: 1560px;
        margin: 0 auto;
        padding-bottom: 1px;

        @include breakpoint('max-width') {
            margin: 0 getSpacing('section-left-right', 'desktop');
        }
        @include breakpoint('tablet') {
            margin: 0 getSpacing('section-left-right', 'tablet');
        }
        @include breakpoint('mobile') {
            margin: 0 getSpacing('section-left-right', 'mobile');
        }
    }

</style>
